import React, { useState, useEffect } from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { isUserLoggedIn, logOut, getOgImage, removeHost } from "../../utils/functions";
import { isEmpty } from 'lodash';
import { navigate } from "gatsby";
// import "./style.scss";
import AccoutNavigation from "../../components/customer-account/navigation";
import Orders from "../../components/customer-account/orders";
import { WPViewContent } from "../../utils/WPpixel";

const MyAccountOrdersPage = (props) => {

  const {
    pageContext: {
      title, seo, uri, acfMyaccountOrders
    }
  } = props;
  
  useState(()=>{
    WPViewContent('View');
  },[]) 

  const { code: locale } = props.pageContext.wpmlTranslated.current[0];
  const { node: translated } = props.pageContext.wpmlTranslated
  const lang = locale.substring(0, 2);

  const { navigation } = acfMyaccountOrders
  const { loginPage } = navigation
  const [loggedIn, setLoggedIn] = useState(false);

  const auth = isUserLoggedIn();

  useEffect(() => {
    const auth = isUserLoggedIn();

    if (!isEmpty(auth)) {
      setLoggedIn(true);
    }
    else {
      navigate(removeHost(loginPage.url))
    }
  }, [loggedIn]);

  const handleLogout = () => {
    logOut();
    setLoggedIn(false);
    navigate(removeHost(loginPage.url))
  };
  // <CustomerAccount handleLogout={handleLogout} />
  //
  return (
    <Layout lang={lang} page="page" translated={translated}>
      {
        !isEmpty(props.pageContext) ? (
          <>
            {loggedIn && (
              <div className="page myAccount">
                <SEO
                  title={title}
translated={translated}
                  seoData={seo}
                  uri={uri}
                  lang={lang}
                  page={"myAccountOrders"}
                  openGraphImage={getOgImage(seo)}
                />
                <div className="container">
                  <AccoutNavigation navigation={navigation} handleLogout={handleLogout} />
                  <div className="myAccount__content">
                    <div className="orders">
                      <Orders orders={acfMyaccountOrders} authData={auth} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div>Something went wrong</div>
        )
      }
    </Layout>
  );
};

export default MyAccountOrdersPage;
