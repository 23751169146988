import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import GET_CUSTOMER_ORDER from "../../../queries/get-customer-orders";
import { isEmpty } from "lodash";
import { getFormattedDate } from "../../../utils/functions";
import Spin from "../../spin/Spin";
import './style.scss';

const Orders = (props) => {
  // console.log(props)
  const id = props.authData.user.databaseId;
  const {
    title,
    descriptionBeforeTable,
    tabelTextOrder,
    tableTextOrderPlaced,
    tableTextOrderMethod,
    tableTextOrderStatus,
    tableTextOrderTotal,
    tableTextProducts,
    textOrdersNotFound,
  } = props.orders;

  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState(true);
  // Get Cart Data.
  const { data, refetch } = useQuery(GET_CUSTOMER_ORDER, {
    variables: {
      customerId: id,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setLoading(false)
      setOrders(data.customer.orders)
    },
    onError: (errors) => {
      // if (errors) {
      //   setRequestError(error?.graphQLErrors?.[0]?.message ?? '');
      // }
    },
  });

  // if (isEmpty(data)) {
  //   return (
  //     <>
  //       <h1>{title}</h1>
  //       <div className="myAccount__spin">
  //         <Spin />
  //       </div>
  //     </>
  //   );
  // }

  return (
    <>
      <h1>{title}</h1>
      <div className="content">

        {loading ? (
          <div className="myAccount__spin">
            <Spin />
          </div>
        ) : (
          <>
            {!isEmpty(orders.edges) ?
              (
                <div className="myAccountOrders">
                  {descriptionBeforeTable ? (
                    <div className="descriptionBeforeTable__description">
                      <div dangerouslySetInnerHTML={{ __html: descriptionBeforeTable }} />
                    </div>
                  ) : ''}
                  {orders.edges.map((order) => {
                    return (
                      <div className="myAccountOrders__item" key={order.node.orderKey}>
                        <div className="myAccountOrders__item__info">
                          <p>{tabelTextOrder} <span>#</span><span dangerouslySetInnerHTML={{ __html: order.node.orderNumber }} /></p>
                          <p><time>{tableTextOrderPlaced} <span>{getFormattedDate(order.node.date)}</span></time></p>
                          <p>{tableTextOrderMethod} <span dangerouslySetInnerHTML={{ __html: order.node.paymentMethodTitle }} /></p>
                          <p>{tableTextOrderStatus} <span dangerouslySetInnerHTML={{ __html: order.node.status }} /></p>
                          <p>{tableTextOrderTotal} <span dangerouslySetInnerHTML={{ __html: order.node.total }} /></p>
                        </div>
                        <div className="myAccountOrders__item__products">
                          <p>{tableTextProducts} </p>
                          <div className="myAccountOrders__item__products__item" >
                            {order.node.lineItems.edges.map((item) => {
                              return (
                                <p key={item.node.product.node.id}>{item.node.product.node.name}</p>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  }
                  )}
                </div>
              ) : (
                <div className="content">
                  <div dangerouslySetInnerHTML={{ __html: textOrdersNotFound }}></div>
                </div>
              )
            }
          </>
        )}
      </div>
    </>
  );
};

export default Orders;
